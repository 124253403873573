import * as React from "react";
import styled from 'styled-components';

import {ReactNode, useRef, useState} from "react";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";

import media from "../../utilities/media";
import {navlink} from "../../typography";

type IMainNav = {
  children?: ReactNode,
  items: Array<{
    href: string,
    title: string,
  }>
}

export const MainNavStyled = styled.div`
  position: fixed;
  top:0;
  left:0;
  z-index:20;
  width:100%;
  height:60px;
  padding: 10px 15px;
  background-color: #fff;
  transition: transform 0.5s ease-in-out;
  display: flex;
  justify-content: space-between;

  ${media.moreThan("lg")} {  
    height:100px;
    padding: 20px 30px;
    ${props => props.show ? null : 'transform: translateY(-100%);'}
  }
  
  img {
    display: block;
    height: 100%;
  }
`;

const NavList = styled.ul`
  display: none;
  list-style-type: none;
  padding:20px 0 0;
  margin:0;
  
  ${media.moreThan("lg")} {
    display: flex;
  }
`;

const NavListLi = styled.li`
  padding-right: 70px;
`;

const NavLink = styled.a`
  ${navlink};
`;

const MainNav = ({
  children,
  items,
}: IMainNav) => {

  const [scrollPos, setScrollPos] = useState(0)
  const [scrollDown, setScrollDown] = useState(false)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      setScrollDown(currPos.y < prevPos.y);
      setScrollPos(currPos.y);
    }, [], null
  )

  return (
    <MainNavStyled show={scrollPos > -150 || !scrollDown}>
      <div>
        <NavList>
          {items.map((item, index) => (
              <NavListLi key={`item-${index}`}>
                <NavLink href={item.href}>{item.title}</NavLink>
              </NavListLi>
            )
          )}
        </NavList>
      </div>
      {children}
    </MainNavStyled>
  );
}

MainNav.defaultProps = {
}

export default MainNav;
