import * as React from "react";
import styled from 'styled-components';

import {ReactNode, useRef, useState} from "react";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";

import media from "../../utilities/media";
import {footertext} from "../../typography";

type IFooter = {
  items: Array<{
    href: string,
    title: string,
  }>,
  social: Array<{
    href: string,
    title: string,
    icon: string,
  }>
}

export const FooterStyled = styled.div`
  background-color: #454545;
  padding: 40px;
`;

const FooterContent = styled.div`
  ${media.moreThan("md")} {
    display: flex;
    justify-content: space-between;
  }
`;

const NavList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin:0;
  padding:0;
`;

const NavListLi = styled.li`
`;

const NavLink = styled.a`
  display: block;
  ${footertext};
  padding:0 15px 30px;
`;

const SocialList = styled.ul`
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin:0;
  padding:0;
`;

const SocialListLi = styled.li`
`;

const SocialLink = styled.a`
  padding:0 15px;
`;

const SocialIcon = styled.img`
`;

const FooterLogoContent = styled.div`
  display: flex;
  justify-content: center;
  padding:60px 0 40px;
`;

const Logo = styled.img`
  display: block;
  width: 180px;
`;

const Footer = ({
                  social,
                  items,
                }: IFooter) => {

  return (
    <FooterStyled>
      <FooterContent>
        <NavList>
          {items.map((item, index) => (
              <NavListLi key={`item-${index}`}>
                <NavLink href={item.href} target="_blank">
                  {item.title}
                </NavLink>
              </NavListLi>
            )
          )}
        </NavList>

        <SocialList>
          {social.map((item, index) => (
              <SocialListLi key={`item-${index}`}>
                <SocialLink href={item.href} title={item.title} target="_blank">
                  <SocialIcon src={item.icon} alt={item.title}/>
                </SocialLink>
              </SocialListLi>
            )
          )}
        </SocialList>
      </FooterContent>
      <FooterLogoContent>
        <Logo src="images/logo_duravit_white.svg"/>
      </FooterLogoContent>
    </FooterStyled>
  );
}

Footer.defaultProps = {}

export default Footer;
