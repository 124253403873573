import * as React from "react";
import {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import media from "../../utilities/media";

type IBackgroundVideo = {
  video: string,
  containerPosition: number,
  small?: boolean,
  start?: number,
}

const BackgroundVideoStyled = styled.div`
  position: fixed;
  z-index: -1;
  transition: opacity 0.5s ease-in-out;

  top:60px;
  left:0;
  width: 100%;
  height: calc(100% - 60px);
  
  ${media.moreThan("lg")} {
    top:0;
    height: 100%;
  }
  
  ${props => props.small ? `
    top: auto;
    left: auto;
    right:20px;
    bottom:10px;
    width: auto;
    height: calc(100% - 70px);
    max-width: calc(100% - 40px);
    display: flex;
    justify-content:center;
    align-content: center;

    ${media.moreThan("lg")} {
      top:10%;
      left:50%;
      width: 50%;
      max-width: 600px;
      height: 80%;
    }
  ` : null }
  
`;

const Video = styled.video`
    
    ${props => props.small ? `    
    max-width: 100%;
    max-height: 100%;
    ` : `
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    ` }    
`;

const BackgroundVideo = ({
  video,
  containerPosition,
  small,
  start,
}: IBackgroundVideo) => {

  const videoRef = useRef()
  const [videoLocked, setVideoLocked] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (containerPosition > -0.8 && containerPosition < 0.8) {
        // @ts-ignore
        if(!videoLocked && video.paused) {
          setVideoLocked(true)
          // @ts-ignore
          const playPromise = video.play();
          if(playPromise) //IE11
            playPromise.then(() => { setVideoLocked(false)});
        }
      } else {
        // @ts-ignore
        if(!videoLocked && !video.paused) {
          // @ts-ignore
          video.currentTime = 0;
          // @ts-ignore
          video.pause();
        }
      }
    }
  }, [containerPosition])

  const opacity = (containerPosition < -0.5 || containerPosition > start) ? 0 : 1; //2 - (containerPosition);
  const display = (containerPosition === -1 || containerPosition === 1) ? 'none' : 'flex'; //2 - (containerPosition);

  return (
    <BackgroundVideoStyled small={small} style={{opacity, display}}>
      <Video muted loop playsInline ref={videoRef} small={small}>
        <source src={video} type="video/mp4"/>
      </Video>
    </BackgroundVideoStyled>
  );
}

BackgroundVideo.defaultProps = {
  small: false,
  start: 0.5,
}

export default BackgroundVideo;
