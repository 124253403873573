import * as React from "react";

import styled from "styled-components";
import media from "../../utilities/media";

import {ReactNode} from "react";

type IEmbed = {
  children: ReactNode,
  className?: string,
  containerPosition?: number,
  src: string,
}

const EmbedStyled = styled.div`
  position: fixed;
  z-index: 5;
  transition: opacity 0.5s ease-in-out;
  border: none;

  top:120px;
  left:0;
  width: 100%;
  height: calc(100% - 120px);
  
  ${media.moreThan("lg")} {
    top:50%;
    left:50%;
    width: 50%;
    height:0;
    padding-bottom: 50%;
    transform: translateY(-50%);
  }
  
`;

const EmbedIframe = styled.iframe`
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border: none;
`;

const Embed = ({
                 children,
                 className,
                 containerPosition,
                 src,
               }: IEmbed) => {

  const opacity = (containerPosition < -0.3 || containerPosition > 0.3) ? 0 : 1; //2 - (containerPosition);
  const display = ((!media.isMoreThan('lg'))||containerPosition === -1 || containerPosition === 1) ? 'none' : 'block'; //2 - (containerPosition);

  return (
    <EmbedStyled className={className} style={{opacity, display}}>
      <EmbedIframe src={src}>
        {children}
      </EmbedIframe>
    </EmbedStyled>
  );
}

export default Embed;
