import * as React from "react";
import {ReactNode, useRef, useState} from "react";
import styled from 'styled-components';

import media from "../../utilities/media";

import Headline2 from "../../atoms/Headline2/Headline2";
import Topline from "../../atoms/Topline/Topline";
import { arrowtext } from "../../typography";
import lightr from "../../utilities/lightr/lightr";

type ITextContent = {
  children?: ReactNode,
  centered?: boolean,
  top?: number,
  containerPosition: number,
  headline: string,
  topline: string,
  inverted?: boolean,
  icon?: string,
  accelerate?: boolean,
  arrowText?: string,
  size?: string,
  marginTop?: number,
  marginBottom?: number,
}

const TextContentStyled = styled.div`
  transition: opacity 0.5s ease-in-out;
  width: 100%;  
  
  ${props => props.top !== 0 ? `
    position: fixed;
    top: 100px;
    left: 0;
    padding:0 20px;
    z-index:2;
    max-width: 500px;
    
    ${!props.centered ? `
      ${media.moreThan("lg")} {
        top: ${props.top}%;
        bottom: auto;
        left:50px;
        transform: translateY(-50%);
        max-width: 600px;
      }
      ${media.moreThan("xl")} {
        left:200px;
      }
      ${media.moreThan("xxl")} {
        left:300px;
      }
    ` : ''}
    
  ` : `
  `};

  
  ${props => props.centered ? `
    margin:30px auto;
    text-align: center;
    position: relative;
    z-index:2;    
    max-width: 700px;
    
    ${props.marginTop ? `margin-top: ${props.marginTop}px;` : ''};
    ${props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : ''};
    
    ${props.top !== 0 ? `
      margin:0;
      left:50%;
      transform: translateX(-50%);
    ` : ''}
    
  ` : ''}
  
  ${props => props.inverted ? `
    h2, p {
      color: #fff;
      
      &:after {
        border-color: #fff;
      }
    }
  ` : null}
  
  ${props => props.size == 'desktop' ? `
    ${media.lessThan("md")} {
      display: none !important;
    }
  ` : null}  
  
  ${props => props.size == 'mobile' ? `
    ${media.moreThan("md")} {
      display: none !important;
    }
  ` : null}  

`;

const ArrowText = styled.div`
  ${arrowtext}
  margin-top:30px;
  
  &:after {
    content:"";
    display: block;
    width:13px;
    height: 47px;
    background-image:url("images/icon_arrow.svg");
    margin:20px auto 0;
  }
`;

const TextContent = ({
  children,
  centered,
  top,
  containerPosition,
  headline,
  topline,
  inverted,
  icon,
  accelerate,
  arrowText,
  size,
  marginTop,
  marginBottom,
}: ITextContent) => {

  const display = (containerPosition > -0.8 && containerPosition < 0.8) ? 'block' : 'none';
  const opacity = (containerPosition > -0.5 && containerPosition < 0.5) ? 1 : 0;

  const translation = accelerate ?
    20 * (containerPosition * containerPosition * containerPosition * 3 + containerPosition) :
    (containerPosition * 1.5 * 100);
  let baseTranslation = '';
  if(media.isMoreThan('lg') && !centered)
    baseTranslation = 'translateY(-50%)';
  if(centered && top)
    baseTranslation = 'translateX(-50%)';
  const transform = `${baseTranslation} translateY(${ translation }vh)`;

  return (
    <TextContentStyled
      centered={centered}
      top={top}
      inverted={inverted}
      size={size}
      marginTop={marginTop}
      marginBottom={marginBottom}
      style={{
        transform,
        display,
        opacity,
      }}>
      {topline ? <Topline icon={icon}>{topline}</Topline> : null}
      {headline ? <Headline2 line={!centered}>{lightr(headline)}</Headline2> : null}
      {children}
      {arrowText ? (
        <ArrowText>
          {arrowText}
        </ArrowText>
      ) : null}
    </TextContentStyled>
  );
}

TextContent.defaultProps = {
  top: 0,
  inverted: false,
  icon: '',
  accelerate: true,
  arrowText: '',
  size: '',
  marginTop: 0,
  marginBottom:0,
}

export default TextContent;
