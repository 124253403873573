import * as React from "react";

import styled from "styled-components";
import media from "../../utilities/media";

import {copy, productsubline} from "../../typography";
import lightr from "../../utilities/lightr/lightr";

type IProductList = {
  products: Array<{
    image: string,
    title: string,
    subline: string,
    link: string,
  }>,
  className?: string,
  containerPosition?: number,
}

const ProductListStyled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-bottom: 80px;
  
  justify-content: space-between;
`;

const Product = styled.a`
  display: block;
  text-decoration: none;
  width: 100%;
  padding-bottom:30px;
  
  ${media.moreThan("md")} {
    width: calc(100% / 2);
  }
  
  ${media.moreThan("lg")} {
    width: calc(100% / 3);
  }

  &:after {
    content:"";
    display: block;
    width:47px;
    height: 13px;
    background-image:url("images/icon_arrow_r.svg");
    margin:10px auto 0;
  }
`;

const ProductImage = styled.div`
  display: flex;
  align-items: center;
  width: 260px;
  // height: 260px;
  padding: 0;
  margin:0 auto;
  
  img {
    display: block;
    width: 100%;
  }
`;

const ProductTitle = styled.p`
  ${copy};
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`;

const ProductSubline = styled.p`
  ${productsubline};
  margin:0;
  text-align: center;
`;

const ProductList = ({
                 products,
                 className,
                 containerPosition,
               }: IProductList) => {

  const opacity = (containerPosition < -0.5 || containerPosition > 0.5) ? 0 : 1; //2 - (containerPosition);
  const display = (containerPosition === -1 || containerPosition === 1) ? 'none' : 'flex'; //2 - (containerPosition);


  return (
    <ProductListStyled className={className} style={{opacity, display}}>
      {products.map( (item, index) => (
        <Product href={item.link} target="_blank" key={`item-${index}`}>
          <ProductImage>
            <img src={item.image}/>
          </ProductImage>
          <ProductTitle>{lightr(item.title)}</ProductTitle>
          <ProductSubline>{item.subline}</ProductSubline>
        </Product>
      ))}
    </ProductListStyled>
  );
}

export default ProductList;
