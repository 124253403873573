import * as React from "react";
import {useEffect, useRef, useState} from "react";
import styled from 'styled-components';

type ITextSection = {
  folder: string,
  containerPosition: number,
}

const ScrubbingContentStyled = styled.div`
  // position: fixed;
  // // top:100px;
  // top:0;
  // left:50%;
  // transform: translateY(-20%);
  margin-top: -20%;
  margin-bottom: -10%;
  z-index:-1;
  display: flex;
  justify-content: center;
`;

const CanvasContent = styled.canvas`
  width: calc(100vh / 16 * 9);
  height: 100vh;
  margin:0 auto;
`;

const currentFrame = (folder, index) => (
  `${folder}/frame${index.toString().padStart(4, '0')}.jpg`
)

const preload = (folder, from, to) => {
  const images = [];
  for (let i=from;i<=to;i++) {
    const img = new Image();
    img.src = currentFrame(folder, i);
    images[i] = img;
  }
  return images;
}

const ScrubbingContent = ({
                            folder,
                            containerPosition,
                          }: ITextSection) => {
  const canvasRef = useRef()
  const [preloadedImages, setPreloadedImages] = useState([]);

  const frameCount = 130; //150; //78;

  const frameIndex = Math.min(
    frameCount - 1,
    frameCount - Math.floor(Math.max(Math.min((containerPosition + 0.1) * 2, 1), 0) * frameCount)
  );// + 21;

  useEffect(() => {
    const images = preload(folder, 1, 129);
    setPreloadedImages(images);
  }, [])

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      // @ts-ignore
      const context = canvas.getContext("2d");
      const img = preloadedImages[frameIndex];
      if(img)
        context.drawImage(img, 0, 0);
    }
  }, [frameIndex]);

  const opacity = Math.min(1, 1 - Math.abs(containerPosition));
  const translation = containerPosition * 0.9 * 100;
  const transform = `translateY(${ translation }vh)`;

  return (
    <ScrubbingContentStyled>
      <CanvasContent
        style={{
          opacity,
          // transform,
        }}
        ref={canvasRef}
        width={1080}
        height={1920}
      />
    </ScrubbingContentStyled>
  );
}

export default ScrubbingContent;
