import * as React from "react";
import styled from "styled-components";
import media from "../../utilities/media";

type IContentImage = {
  image: string,
  containerPosition: number,
}

const CutOutImageStyled = styled.div`
  position: static;
  z-index: 1;
  margin:-25px auto 0;
`;

const Img = styled.img`
  display: block;
  margin:0 auto;
`;

const CutOutImage = ({
                           image,
                           containerPosition,
                         }: IContentImage) => {

  const display = (containerPosition === -1 || containerPosition === 1) ? 'none' : 'block'; //2 - (containerPosition);

  return (
    <CutOutImageStyled style={{display}}>
      <Img src={image}/>
    </CutOutImageStyled>
  );
}

CutOutImage.defaultProps = {
  visible: false,
}

export default CutOutImage;
