import Container from "../../atoms/Container/Container";
import * as React from "react";
import {ReactNode, useEffect, useRef} from "react";
import {animated, useSpring} from 'react-spring';
import styled from "styled-components";
import media from "../../utilities/media";
import {easeOutSine} from "js-easing-functions";

type IParallaxVideo = {
  video: string,
  poster: string,
  containerPosition: number,
}

const ParallaxVideoStyled = styled(animated.div)`
  position: relative;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 80vw;
  // min-height:100%;
  position: relative;
  background-color: #000;
  transition: opacity 0.5s ease-in-out;

  ${media.moreThan("lg")} {
    margin-top: 100px;
    height: calc(100vh - 120px);
    // max-height: 70vw;
  }
`;

const Video = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
`;

const ParallaxVideo = ({
  video,
  poster,
  containerPosition,
}: IParallaxVideo) => {

  const videoRef = useRef()

  //   useEffect(() => {
  //   if(videoRef.current && videoRef.current != undefined) {
  //     if(containerPosition != -1 && containerPosition != 1) {
  //       // @ts-ignore
  //       videoRef.current.play();
  //     } else {
  //       // @ts-ignore
  //       videoRef.current.pause();
  //     }
  //   }
  // }, [containerPosition])

  const translation = easeOutSine(containerPosition, 0, -30, 0.5);

  const transform = `translateY(${translation}%)`;

  return (
    <ParallaxVideoStyled style={{
      transform
    }}>
      <VideoContainer>
        <Video autoPlay muted playsInline loop ref={videoRef} poster={poster}>
          <source src={video} type="video/mp4"/>
        </Video>
      </VideoContainer>
    </ParallaxVideoStyled>
  );
}

ParallaxVideo.defaultProps = {}

export default ParallaxVideo;
