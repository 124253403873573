import media from "./utilities/media";

import {css} from 'styled-components';

export const copy = css`
  font-family: "Duravit Sans", sans-serif;
  font-size: 16px;
  line-height: 26.5px;
  font-weight: normal;
  color: #464646;
  
  ${media.moreThan("md")} {
    font-size: 20px;
    line-height: 33px;
  }
  
  p {
    margin:0 0 1em;
    
    &:last-child {
      margin-bottom:0;
    }
  }
  
  h3 {
    margin:1em 0 0;
  }
  
  ul {
    margin:0 0 1em;
    list-style-type: none;
    padding-left:40px;
    
    li {
      position: relative;
      &:before {
        content:"";
        display: block;
        background-color: #1F1F1F;
        border-radius: 50%;
        width:3px;
        height:3px;
        position: absolute;
        top:12px;
        left:-30px;
      }
    }
  }
`;

export const headline1 = css`
  font-family: "Duravit Sans", sans-serif;
  font-size: 60px;
  font-weight: normal;
  text-align: center;
  line-height: 1.2;
  color: #939393;

  ${media.moreThan("md")} {
    font-size: 52px;
  }

`;

export const headline2 = css`
  font-family: "Duravit Sans", sans-serif;
  font-size: 28px;
  text-transform: none;
  font-weight: bold;
  line-height: 1.2;
  color: #000;
  margin-top:0;
  margin-bottom: 10px;
  ${media.moreThan("md")} {
    font-size: 40px;
    margin-bottom: 40px;
  }
`;

export const headline3 = css`
  font-family: "Duravit Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  color: #1F1F1F;
  margin-top:0;
  margin-bottom:1em;
`;

export const toplinetext = css`
  font-family: "Duravit Sans", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #000;
`;

export const footertext = css`
  font-family: "Duravit Sans", sans-serif;
  font-size: 14px;
  letter-spacint:0px;
  text-decoration: none;
  line-height: 1;
  font-weight: 300;
  color: #fff;
  
`;

export const buttontext = css`
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  border:2px solid #fff;
`;

export const arrowtext = css`
  font-family: "Duravit Sans", sans-serif;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 3px;
  color: #000;
`;

export const productsubline = css`
  font-family: "Duravit Sans", sans-serif;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000;
`;

export const navlink = css`
  font-family: "Duravit Sans", sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1.14px;
  color: #000;  
`;

export const disturbertext = css`
  font-family: "Duravit Sans", sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-align: center;  
  color: #fff;
`;