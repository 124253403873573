import * as React from "react";
import {ReactNode} from "react";
import styled from 'styled-components';

import {toplinetext} from '../../typography';

type ITopline = {
  children?: ReactNode,
  icon?: string,
}

export const ToplineStyled = styled.p`
  display: flex;
  align-items: center;
  ${toplinetext}
`;

const Icon = styled.img`
  display: block;
  width: 50px;
  height: 50px;
  margin-right: 20px;  
`;

const Topline = ({
  children,
  icon,
}: ITopline) => (
  <ToplineStyled>
    {icon ? <Icon src={icon}/> : null}
    {children}
  </ToplineStyled>
)

Topline.defaultProps = {
  icon: '',
}

export default Topline;
