import * as React from "react";
import jquery from 'jquery';

const Analytics = () => {

  const script = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MPCL6W');
  `;

  if(typeof window !== "undefined") {
    window['$'] = window['jQuery'] = jquery;
  }

  return (
    <>
      <script
        type="text/plain"
        data-type="application/javascript"
        data-usercentrics="Google Tag Manager"
        dangerouslySetInnerHTML={{__html: script}}
      />
    </>
  );
}

export default Analytics;
