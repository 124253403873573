import * as React from "react";
import styled from "styled-components";
import media from "../../utilities/media";

type IContentVideo = {
  src: string,
  containerPosition: number,
}

const ContentVideo = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1400px;
  padding-bottom: calc(100% * 9/16);
  margin:0 auto;
`;

const Iframe = styled.iframe`
  display: block;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
`;

const CutOutImage = ({
                           src,
                           containerPosition,
                         }: IContentVideo) => {

  const display = (containerPosition === -1 || containerPosition === 1) ? 'none' : 'block'; //2 - (containerPosition);

  return (
    <ContentVideo style={{display}}>
      <Iframe src={src} width="640" height="360" frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></Iframe>
    </ContentVideo>
  );
}

CutOutImage.defaultProps = {
  visible: false,
}

export default CutOutImage;
