import * as React from "react";
import styled from "styled-components";
import media from "../../utilities/media";

type IBackgroundImage = {
  image: string,
  containerPosition: number,
  visible?: boolean,
}

const CutOutImageStyled = styled.div`
  position: fixed;
  z-index: 1;
  transition: opacity 0.5s ease-in-out;
  bottom:0;
  left:0;
  width: 80%;
  height: 80%;
  
  ${media.moreThan("md")} {
    width: 50%;
    height: 80%;
  }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom left;
`;

const CutOutImage = ({
                           image,
                           containerPosition,
  visible,
                         }: IBackgroundImage) => {

  const opacity = visible ? 1 : 0; //(containerPosition < -0.5 || containerPosition > 0.5) ? 0 : 1; // - Math.abs(containerPosition * 2);
  const display = (containerPosition === -1 || containerPosition === 1) ? 'none' : 'block'; //2 - (containerPosition);

  return (
    <CutOutImageStyled style={{opacity, display}}>
      <Img src={image}/>
    </CutOutImageStyled>
  );
}

CutOutImage.defaultProps = {
  visible: false,
}

export default CutOutImage;
