import * as React from "react";
import styled from 'styled-components';
import { copy } from "../../typography";
import {ReactNode} from "react";

type IText = {
  children: ReactNode,
  className?: string,
}

const TextStyled = styled.div`
  ${copy};
`;

const Text = ({
  children,
  className,
}: IText) => (
  <TextStyled className={className}>{children}</TextStyled>
)

export default Text;
