import * as React from "react";
import {Lighter} from "../../atoms/Lighter/Lighter";

const lightr = ( text ) => {
  const parts = text.split('®');
  const result = [];
  parts.forEach((part, index) => {
    result.push(part);
    if(index !== (parts.length - 1)) {
      result.push(<Lighter key={`l-${index}`}>®</Lighter>);
    }
  })
  return <span>{result}</span>;
}

export default lightr;