import React from "react"
import 'promise-polyfill/src/polyfill';
// import {graphql} from "gatsby"
// import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ParallaxContainer from "../components/atoms/ParallaxContainer/ParallaxContainer";
import TextContent from "../components/molecules/TextContent/TextContent";
import Text from "../components/atoms/Text/Text";
import BackgroundVideo from "../components/molecules/BackgroundVideo/BackgroundVideo";
import ParallaxVideo from "../components/molecules/ParallaxVideo/ParallaxVideo";
import ScrubbingContent from "../components/molecules/ScrubbingContent/ScrubbingContent";
import BackgroundImage from "../components/molecules/BackgroundImage/BackgroundImage";
import MainNav from "../components/molecules/MainNav/MainNav";
import CutOutImage from "../components/molecules/CutOutImage/CutOutImage";
import Button from "../components/atoms/Button/Button";
import Embed from "../components/molecules/Embed/Embed";
import ProductList from "../components/molecules/ProductList/ProductList";
import ContentImage from "../components/molecules/ContentImage/ContentImage";
import ContentVIdeo from "../components/molecules/ContentVideo/ContentVIdeo";
import Footer from "../components/molecules/Footer/Footer";
import {Lighter} from "../components/atoms/Lighter/Lighter";
import NoBr from "../components/atoms/NoBr/NoBr";

const IndexPage = ({
  data
}) => {
  return (
    <Layout>
      <Seo title="Home" lang="de"/>
      <MainNav
        items={[
          {
            href: '#features',
            title: 'Sensowash® Features'
          },
          {
            href: '#360degree',
            title: '360° View',
          },
          {
            href: '#haendlersuche',
            title: 'Händlersuche',
          }
        ]}
      >
        <img src="images/logo_duravit.svg" alt=""/>
      </MainNav>
      <ParallaxContainer
        background={position => (
          <ParallaxVideo
            containerPosition={position}
            poster={'images/video_poster.jpg'}
            video="https://player.vimeo.com/external/556096535.hd.mp4?s=3a1d5c1a6abe3ae421a80cf87cf0a2ac9a45a177&profile_id=175"
          />
        )}
      />
      <ParallaxContainer
        minHeight="50vh"
        zIndex={-1}
        overflow="visible"
        content={position => (
          <>
          <TextContent
            headline="SensoWash®, öffne dich."
            top={40}
            centered
            containerPosition={0}
          >
            <Text>
              <p>Der eingebaute Bewegungssensor unseres Dusch-WCs erkennt, wenn Sie sich nähern – und lässt das
                berührungslose, automatische Öffnen und Schließen des Deckels aussehen wie einen lässigen Zaubertrick. </p>
            </Text>
          </TextContent>
          <ScrubbingContent folder={'opening'} containerPosition={position} />
          </>
        )}
      />
      <ParallaxContainer
        content={position => (
          <TextContent
            centered
            containerPosition={0}
            arrowText="SensoWash® Starck f entdecken"
          ></TextContent>
        )}
      />
      <ParallaxContainer
        minHeight="100vh"
        id="features"
        content={position => (
          <TextContent
            containerPosition={position}
            headline="Eine saubere Sache."
            topline="Ladydusche"
            icon="sw-icons/ladydusche.svg"
            top={70}
          >
            <Text>
              <p>Entwickelt für die speziellen Bedürfnisse von Benutzer*innen reinigt die Ladydusche besonders sanft und
                hinterlässt ein angenehmes, hygienisches Frischegefühl.</p>
            </Text>
          </TextContent>
        )}
        background={position => (
          <BackgroundVideo
            containerPosition={position}
            start={0.7}
            video="https://player.vimeo.com/external/544004694.hd.mp4?s=f3864b86565726a7115a7039c6ca495a33a3806f&profile_id=175"/>
        )}
      />
      <ParallaxContainer
        minHeight="100vh"
        content={position => (
          <TextContent
            containerPosition={position}
            headline="Perfekt in Position."
            topline="Gesäßdusche"
            icon="sw-icons/gesaessdusche.svg"
            top={70}
          >
            <Text>
              <p>Die Duschstabposition und Duschstrahlintensität der Gesäßdusche sind individuell einstellbar und sorgen
                so nicht nur für eine angenehme Reinigung, sondern auch für maximalen Komfort.</p>
            </Text>
          </TextContent>
        )}
        background={position => (
          <BackgroundVideo
            containerPosition={position}
            video="https://player.vimeo.com/external/544004711.hd.mp4?s=8829c97575db19c9dcc367893e594af23aeb05b3&profile_id=175"/>
        )}
      />

      <ParallaxContainer
        minHeight="100vh"
        content={position => (
          <TextContent
            containerPosition={position}
            headline="Einfach mal sitzen bleiben."
            topline="Integrierte Sitzheizung"
            icon="sw-icons/sitzheizung.svg"
            top={50}
          >
            <Text>
              <p>Die Zeiten kalter Keramik sind vorbei: Die Sitzheizung des SensoWash<Lighter>®</Lighter> Starck f lässt sich dank Temperaturregelung
                ganz nach Ihren Vorlieben einstellen. Ein vorgewärmter Sitz war noch nie angenehmer!
              </p>
            </Text>
          </TextContent>
        )}
        background={position => (
          <BackgroundVideo
            containerPosition={position}
            small
            video="https://player.vimeo.com/external/544004769.hd.mp4?s=ae8c6d5880bced276cb70dd48e20f163d6a93152&profile_id=174"/>
        )}
      />

      <ParallaxContainer
        minHeight="100vh"
        content={position => (
          <TextContent
            containerPosition={position}
            headline="Wir bringen frischen Wind."
            topline="Warmluftföhn"
            icon="sw-icons/warmluftfoehn.svg"
            top={50}
          >
            <Text>
              <p>Also gut, eigentlich fühlt sich der Warmluftföhn unseres Dusch-WCs eher an wie eine sanfte Brise. Nach
                Anwendung der Duschfunktionen trocknet der Föhn behutsam und mit individuell temperierbarem Luftstrom.
              </p>
            </Text>
          </TextContent>
        )}
        background={position => (
          <BackgroundVideo
            containerPosition={position}
            small
            video="https://player.vimeo.com/external/544004683.hd.mp4?s=fb94c7c275bdf6aa3c16cf70055279ed78f23b9a&profile_id=174"/>
        )}
      />

      <ParallaxContainer
        minHeight="100vh"
        content={position => (
          <TextContent
            containerPosition={position}
            headline="Ein Hauch von nichts."
            topline="Geruchsabsaugung"
            icon="sw-icons/geruchsabsaugung.svg"
            top={50}
          >
            <Text>
              <p>Kein Grund, die Nase zu rümpfen: Dank integrierter Geruchsabsaugung ist unser SensoWash<Lighter>®</Lighter> Starck f garantiert
                kein Erlebnis für die Sinne. Einfach zu bedienen per App oder Fernbedienung.
              </p>
            </Text>
          </TextContent>
        )}
        background={position => (
          <BackgroundVideo
            containerPosition={position}
            small
            video="https://player.vimeo.com/external/544004642.hd.mp4?s=b38edea3572ada061feb75c2206eefbffeff128f&profile_id=174"/>
        )}
      />

      <ParallaxContainer
        minHeight="100vh"
        content={position => (
          <TextContent
            containerPosition={position}
            headline="Randlos glücklich."
            topline="Duravit Rimless®"
            icon="sw-icons/rimless.svg"
            top={50}
          >
            <Text>
              <p>Sauberkeit und Hygiene bis ins letzte Detail:<br/>
                Die optimale Beckengestaltung und innovative<br/>
                Spültechnologie
                sorgen in Kombination für perfekte Spülergebnisse.
              </p>
            </Text>
          </TextContent>
        )}
        background={position => (
          <BackgroundVideo
            containerPosition={position}
            small
            video="https://player.vimeo.com/external/544004743.hd.mp4?s=a18b5c5e41d0bf4ca207574003b12e79a7132c28&profile_id=174"/>
        )}
      />


      <ParallaxContainer
        minHeight="200vh"
        max={-1.5}
        content={position => {
          const pos = (position - 0.5) / 2 + 0.5;
          return (
            <TextContent
              containerPosition={pos}
              headline="Alles unter Kontrolle."
              topline="Fernbedienung/App"
              icon="sw-icons/fernbedienung.svg"
              top={50}
              accelerate={false}
            >
              <Text>
                <p>Alle Funktionen des <NoBr>SensoWash<Lighter>®</Lighter> Starck f</NoBr><br/>
                  sind nur einen Knopfdruck entfernt. Und wer<br/>
                  auf mehr Individualismus steht, kann die<br/>
                  Einstellungen des Dusch-WCs über die App<br/>
                  frei nach den eigenen Vorlieben konfigurieren.
                </p>
              </Text>
            </TextContent>
          );
        }}
        background={position => {
          let pos = (position - 0.5) / 2 + 0.5;
          if(pos === -0.5)
            pos = -1;
          return (
            <>
              <BackgroundImage
                containerPosition={pos}
                image="images/bg_fernbedienung.jpg"/>
              <CutOutImage
                containerPosition={pos}
                visible={pos > 0 && pos <= 0.5}
                image="images/cutout_fernbedienung.png"/>
              <CutOutImage
                containerPosition={pos}
                visible={pos <= 0 && pos >= -0.5}
                image="images/duravit_app.png"/>
            </>
          );
        }}
      />

      <ParallaxContainer
        minHeight="50vh"
        background={position => (
          <BackgroundImage
            containerPosition={position}
            imgPosition="right"
            image="images/bathroom_light.jpg"/>
        )}
      />
      <ParallaxContainer
        minHeight="100vh"
        content={position => (
          <TextContent
            containerPosition={position}
            headline="Es werde Licht."
            topline="Nachtlicht"
            icon="sw-icons/nachtlicht.svg"
            top={50}
            inverted
          >
            <Text>
              <p>Das Nachtlicht unseres Dusch-WCs bringt immer dann eine Erleuchtung, wenn man sie gerade braucht.
                Dezente Beleuchtung sorgt im Dunkeln für Orientierung, ohne zu blenden.
              </p>
            </Text>
          </TextContent>
        )}
        background={position => (
          <BackgroundImage
            containerPosition={position}
            imgPosition="right"
            image="images/bathroom_dark.jpg"/>
        )}
      />
      <ParallaxContainer
        minHeight="110vh"
        minHeightMobile="70vh"
        backgroundColor="#F0F6FA"
        id="360degree"
        content={position => (
          <>
            <TextContent
              containerPosition={position}
              headline="Entdecken Sie das SensoWash® Starck f aus jeder Perspektive."
              topline="AR-Anwendung"
              icon="sw-icons/ar-app.svg"
              top={50}
              size="desktop"
            >
              <Text>
                <p> Lernen Sie unser Dusch-WC von jeder Seite kennen: <br/>
                  Unser virtueller 360°-View zeigt Ihnen alle
                  Facetten des <NoBr>SensoWash<Lighter>®</Lighter> Starck f</NoBr> im Detail – für einen rundum <br/>
                  perfekten ersten Eindruck.
                </p>
                <p>Jetzt auf dem Smartphone öffnen:</p>
                <img src="images/qrcode_black.png" alt="" style={{width:105}}/>
              </Text>
            </TextContent>
            <TextContent
              containerPosition={position}
              headline="Holen Sie sich das SensoWash® Starck f virtuell nach Hause."
              topline="AR-Anwendung"
              icon="sw-icons/ar-app.svg"
              size="mobile"
            >
              <Text>
                <p> Bringen Sie das <NoBr>SensoWash<Lighter>®</Lighter> Starck f</NoBr> in Ihr eigenen vier Wände: Mithilfe der AR-Funktion
                  können Sie das Dusch-WC an einer Wand Ihrer Wahl platzieren. Ein gutes Gefühl, oder?
                </p>
                <p>
                  <br/><Button variant="dark" href="https://viewer.vrxs.de/embedded-model/1ca61f2d18232e0b806aefb31c6c4d39">AR-Anwendung starten</Button>
                </p>
              </Text>
            </TextContent>
            <Embed
              containerPosition={position}
              src="https://viewer.vrxs.de/embedded-model/1ca61f2d18232e0b806aefb31c6c4d39?white_label=true"
            />
          </>
        )}
        background={position => {}}
      />
      <ParallaxContainer
        content={position => (
          <>
            <TextContent
              containerPosition={0}
              headline="Mehr als nur ein Dusch-WC."
              marginTop={60}
              marginBottom={60}
              centered
            >
              <Text>
                <p>Erfahren Sie die Geschichte hinter dem Duravit SensoWash<Lighter>®</Lighter> Starck f und lernen Sie den verantwortlichen
                  Designer Philippe Starck kennen.
                </p>
              </Text>
            </TextContent>
            <ContentVIdeo src="https://player.vimeo.com/video/555241361" containerPosition={0}/>
          </>
        )}
        background={position => {}}
      />
      <ParallaxContainer
        backgroundColor="#F6F4F2"
        content={position => (
          <>
            <TextContent
              containerPosition={0}
              headline="Sie haben die Wahl."
              centered
              marginTop={60}
              marginBottom={60}
            >
              <Text>
                <p>Das SensoWash<Lighter>®</Lighter> Starck f ist erst der Anfang unserer innovativen Duravit-Welt. Entdecken
                  Sie jetzt weitere SensoWash<Lighter>®</Lighter> Produkte für Ihr smartes Badezimmer!
                </p>
              </Text>
            </TextContent>
            <ProductList
              products={[
                {
                  image: 'products/sewa-starck-f_frei.png',
                  title: 'SensoWash® Starck f Plus',
                  subline: 'Zur Produktseite',
                  link: 'https://www.duravit.de/produkte/alle_serien/sensowash_starck_f.de-de.html?product=5762774',
                },
                {
                  image: 'products/sewa-starck-f_frei.png',
                  title: 'SensoWash® Starck f Lite',
                  subline: 'Zur Produktseite',
                  link: 'https://www.duravit.de/produkte/alle_serien/sensowash_starck_f.de-de.html?product=5824224',
                },
                {
                  image: 'products/sewa-slim_frei.png',
                  title: 'SensoWash® Slim',
                  subline: 'Zur Produktseite',
                  link: 'https://www.duravit.de/produkte/alle_serien/sensowash_slim.de-de.html?product=3844235'
                },
              ]}
            />
          </>
        )}
        background={position => {}}
      />
      <ParallaxContainer
        // minHeight="100vh"
        backgroundColor="#7FA3C8"
        id="haendlersuche"
        content={position => (
          <>
            <ContentImage
              containerPosition={0}
              image={"images/wc_oben.png"}
            />
            <TextContent
              containerPosition={0}
              headline="Finden Sie Ihren Duravit-Fachhändler."
              centered
              inverted
              marginTop={60}
              marginBottom={60}
            >
              <Text>
                <p>Vereinbaren Sie gleich einen Termin zur Beratung bei einem Duravit-Fachhändler in Ihrer Nähe – kostenlos und flexibel.
                </p>
                <p style={{paddingTop: 50}}><Button href="https://www.duravit.de/realisieren/haendler-_und_ausstellungssuche.de-de.html?utm_medium=button&utm_source=sensowash-landingpage&utm_campaign=sensowash2021#f_series=6220">Händler finden</Button></p>
              </Text>
            </TextContent>
          </>
        )}
        background={position => {}}
      />
      <Footer
        items={[
        {
          title: 'Impressum',
          href: 'https://www.duravit.de/service/unternehmen/kontakt/rechtliches.de-de.html'
        },
        {
          title: 'Datenschutz',
          href: 'https://www.duravit.de/settings/05_footer/footer_links/datenschutzerklaerung.de-de.html'
        },
        {
          title: '© Duravit 2021',
          href: 'https://www.duravit.de'
        }
      ]}
        social={[
          {
            title: 'Youtube',
            href: 'https://www.youtube.com/duravitag',
            icon: 'images/icon_yt.svg'
          },
          {
            title: 'Facebook',
            href: 'https://www.facebook.com/duravitDE',
            icon: 'images/icon_fb.svg'
          },
          {
            title: 'Pinterest',
            href: 'https://www.pinterest.com/duravit',
            icon: 'images/icon_pinterest.svg'
          },
          {
            title: 'Instagram',
            href: 'https://www.instagram.com/duravit',
            icon: 'images/icon_ig.svg'
          },
          {
            title: 'Spotify',
            href: 'https://open.spotify.com/user/p04fk1cuh78yhlq5v6okso3fq?si=ZMSUts_ERqSDYVoavlYvSg&nd=1',
            icon: 'images/icon_spotify.svg'
          },
        ]}
      />
    </Layout>
  );
}

// export const query = graphql`
//     query {
//       image1: file(relativePath: { eq: "roller_3.jpg" }) {
//         childImageSharp {
//           fluid(maxWidth: 2000) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//       image2: file(relativePath: { eq: "roller_2.jpg" }) {
//         childImageSharp {
//           fluid(maxWidth: 2000) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//       image3: file(relativePath: { eq: "roller_1.jpg" }) {
//         childImageSharp {
//           fluid(maxWidth: 2000) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `;

export default IndexPage
