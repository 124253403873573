import * as React from "react";
import styled from 'styled-components';

import media from "../../utilities/media";

import {headline2} from '../../typography';
import {ReactNode} from "react";

type IHeadline = {
  children?: ReactNode,
  line?: boolean,
}

export const Headline2Styled = styled.h2`
  ${headline2}
  
  ${props => props.line ? `
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -5px;
      right:-20px;
      width: 200%;
      border-bottom:1px solid #000;
      
      ${media.moreThan("md")} {
        bottom: -20px;
      }
    }
  ` : null}
`;

const Headline2 = ({
  children,
  line,
}: IHeadline) => (
  <Headline2Styled line={line}>
    {children}
  </Headline2Styled>
)

Headline2.defaultProps = {
  line: false,
}

export default Headline2;
