import * as React from "react";

import styled from "styled-components";
import {buttontext} from "../../typography";
import {ReactNode} from "react";

type IButton = {
  children: ReactNode,
  className?: string,
  href: string,
  variant?: string,
}

const ButtonStyled = styled.a`
  ${buttontext}
  
  ${props => props.variant === 'dark' ? `
    color: #000;
    border-color: #000;
  ` : ''}
`;

const Button = ({
                  children,
                  className,
                  href,
                  variant,
                }: IButton) => (
  <ButtonStyled className={className} href={href} variant={variant} target="_blank" >
    {children}
  </ButtonStyled>
)

Button.defaultProps = {
  variant: ''
}

export default Button;
