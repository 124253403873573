import * as React from "react";
import styled from 'styled-components';
import {ReactNode, useRef, useState} from "react";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";

import media from "../../utilities/media";

type IParallaxContainer = {
  content: (elementPosition:number) => ReactNode,
  background?: (elementPosition:number) => ReactNode,
  backgroundColor?: string,
  minHeight: string | null,
  minHeightMobile: string | null,
  backgroundImage?: string,
  zIndex?: number,
  id?: string | null,
  min?: number,
  max?: number,
  overflow?: string,
}

const ParallaxContainerStyled = styled.div`
  display:flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: ${props => props.overflow};
  z-index:${props => props.zIndex};
  ${props => props.minHeight ? `min-height:${props.minHeight}` : null };
  background-repeat: none;
  background-position: center;
  background-size: cover;
  ${props => props.backgroundColor ?
  `background-color: ${props.backgroundColor}` : null};
  ${props => props.backgroundImage ? 
    `background-image: url('${props.backgroundImage}')` : null};

  ${media.lessThan("md")} {
    ${props => props.minHeightMobile ? `min-height:${props.minHeightMobile}` : null };
  }
`;

const ContainerContent = styled.div`
  position: relative;
  flex:1;
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding:20px;
`;


const ParallaxContainer = ({
  content,
  background,
  minHeight,
  minHeightMobile,
  backgroundImage,
  backgroundColor,
  zIndex,
  id,
  min,
  max,
  overflow,
}: IParallaxContainer) => {

  const [elementPosition, setElementPosition] = useState(1)
  const elementRef = useRef()

  // Element scroll position
  useScrollPosition(
    ({ currPos }) => {
      const pos = Math.max(Math.min(currPos.y / window.innerHeight, min), max)
      setElementPosition(pos)
    }, [], elementRef, false,
  )

  const contentElements = content(elementPosition);

  return (
    <ParallaxContainerStyled
      minHeight={minHeight}
      minHeightMobile={minHeightMobile}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      zIndex={zIndex}
      ref={elementRef}
      overflow={overflow}
      id={id}
    >
      {background(elementPosition)}
      {contentElements ? <ContainerContent>
        {contentElements}
      </ContainerContent> : null}
    </ParallaxContainerStyled>
  );
};

ParallaxContainer.defaultProps = {
  background: () => {},
  content: () => {},
  minHeight: null,
  minHeightMobile: null,
  backgroundImage: null,
  backgroundColor: '',
  zIndex: 0,
  id: null,
  min: 1,
  max: -1,
  overflow: 'hidden',
}

export default ParallaxContainer;
