import * as React from "react";
import styled from "styled-components";
import media from "../../utilities/media";

type IBackgroundImage = {
  image: string,
  containerPosition: number,
  small?: boolean,
  imgPosition?: string,
  to?: number,
}

const BackgroundImageStyled = styled.div`
  position: fixed;
  z-index: -1;
  transition: opacity 0.5s ease-in-out;

  top:0;
  left:0;
  width: 100%;
  height: 100%;
  
  ${props => props.small ? `
  
    ${media.moreThan("md")} {
      top:10%;
      left:50%;
      width: 40%;
      height: 80%;
    }
  ` : `
  `}  
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${props => props.imgPosition} center;
`;

const BackgroundImage = ({
  image,
  containerPosition,
  small,
  imgPosition,
  to,
}: IBackgroundImage) => {

  const opacity = (containerPosition < to || containerPosition > 0.5) ? 0 : 1; // - Math.abs(containerPosition * 2);
  const display = (containerPosition === -1 || containerPosition === 1) ? 'none' : 'block'; //2 - (containerPosition);

  return (
    <BackgroundImageStyled small={small} style={{opacity, display}}>
      <Img src={image} imgPosition={imgPosition}/>
    </BackgroundImageStyled>
  );
}

BackgroundImage.defaultProps = {
  small: false,
  to: -0.5,
  imgPosition: "center",
}

export default BackgroundImage;
